import React, {useState, useCallback} from 'react'
import {graphql} from 'gatsby'
import {useQueryParam, StringParam} from 'use-query-params'

// Services
import {getPlatform, getRapid} from '../../services/'

// Components
import OnesearchTemplate from '../../templates/onesearch'
import CtaDefault from '../../components/Onesearch/CtaDefault'
import HeroDefault from '../../components/Onesearch/HeroDefault'
import PrivacyDefault from '../../components/Onesearch/PrivacyDefault'
import ColumnsDefault from '../../components/Onesearch/ColumnsDefault'

// page
export default function onesearch({data: {content, logoImage}}) {
  const [gclidParam] = useQueryParam('gclid', StringParam)
  const [uuidParam] = useQueryParam('uuid', StringParam)

  const [pageContent] = useState(() => {
    return {
      ...content,
      sub_title: content.default.sub_title,
    }
  })

  const [ctaContent] = useState(() => {
    return content.default
  })

  const onClickHandler = useCallback(() => {
    const platform = getPlatform()
    const rapidInstance = getRapid(content.config.rapid.init)

    const lls = {}
    lls.ll1 = 'onesearch'
    lls.ll2 = platform.browser.alias || platform.ua
    if (gclidParam) lls.ll3 = gclidParam
    if (uuidParam) lls.ll4 = uuidParam

    rapidInstance.beaconClick('page', ctaContent.btn.label, 2, {
      ...rapidInstance._normalizeTar(ctaContent.btn.url),
      itc: 0,
      cpos: 2,
      elm: 'btn',
      ...lls,
    })
  }, [ctaContent])

  return (
    <OnesearchTemplate content={pageContent} logoImage={logoImage}>
      <HeroDefault content={pageContent}>
        <CtaDefault content={ctaContent} onClickHandler={onClickHandler} />
      </HeroDefault>
      <PrivacyDefault />
      <ColumnsDefault>
        <CtaDefault content={ctaContent} onClickHandler={onClickHandler} />
      </ColumnsDefault>
    </OnesearchTemplate>
  )
}

// query
export const pageQuery = graphql`
  query {
    content: onesearchpageJson {
      config {
        rapid {
          init {
            spaceid
            keys {
              pt
              ver
            }
          }
        }
      }
      default {
        sub_title
        desc
        btn {
          url
          label
        }
      }
    }
    logoImage: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "yahoo_logo_purple.png"}) {
      sharp: childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
